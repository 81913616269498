import React, { useRef, useState } from "react";
import { Link } from "gatsby"

import { Swiper, SwiperSlide } from "swiper/react";

import { navigate } from 'gatsby';

import Grid from '@mui/material/Grid';


import Breadcrumbs from '@mui/material/Breadcrumbs';

import "swiper/css";

import {Navigation } from "swiper";
import Paper from '@mui/material/Paper';



import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';

import { useTheme } from '@mui/material/styles';

import { MdOutlineSwipeLeft } from "react-icons/md/";
import { MdOutlineSwipeRight } from "react-icons/md";

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const reviewImages = [
  {
    label: "Picture of of the newspaper logo The Telegraph in the context of the newspaper's review of E.O. Chirovici's Bad Blood ",
    text: 'A highly entertaining box of tricks ',

    imgPath:
      '/images/reviews/telegraph-min.png',
  }
 
];








export default function App(props) {

  

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = reviewImages.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };




  return (

      <>
  <Swiper
        className='bookPage'
        spaceBetween={0}

                centeredSlides={false}



        grabCursor={true}
  


initialSlide={1}
  onSlideChange={(swiperCore) => {
            const {
              activeIndex,
              snapIndex,
              previousIndex,
              realIndex,
            } = swiperCore;

window.scrollTo(0,0)


              if (swiperCore.realIndex === 2) {    navigate('/books/rumours');    };

              if (swiperCore.realIndex === 0) {    navigate('/books/book-of-mirrors');    };





        }}



        pagination={{
          clickable: true,
        }}
      >



              <SwiperSlide  className=' '> </SwiperSlide>





             <SwiperSlide className='white switchToSLC books'> 





<div className='longPage'>




<span className='standardIntroductionText standardBookIntroductionText animate__animated'>


    <Breadcrumbs aria-label="breadcrumb">
        <Link className='breadcrumbLink' to="/">
          Home
        </Link>
          <Link className='breadcrumbLink' to="/books">
          Books
        </Link>
        <Link className='breadcrumbLink' to="/books/bad-blood">
          Bad Blood
        </Link>




      </Breadcrumbs>



<h3  className=' animate__animated'>Bad Blood</h3>

</span>


<Grid className='bookGrid' container spacing={2}>

  <Grid item md={4} xs={4}>


   <span className='bookGridSpan'>Fiction </span>


  </Grid>

  <Grid item md={4} xs={4}>


   <span className='bookGridSpan'>2018 </span>


  </Grid>
  <Grid item md={4} xs={4}>


   <span className='bookGridSpan'>Serpent's Tail </span>


  </Grid>









  </Grid>







<span className='standardIntroductionText animate__animated'>

<p className='animate__animated'>

You can't trust your own memories. You can't trust other people's stories either. So how do you know what really happened that night?
<br/><br/>

Psychologist James Cobb gives a presentation on the art of recovering lost memory on a rainy night in New York. When he leaves, he is approached by a stranger: a dying man, who tells him that, about forty years ago, he woke up in a hotel room with a woman who had been killed, but he does not remember what happened. Cobb needs to know if he's dealing with an innocent man or a murderer. But the threads of this ancient mystery are much more tangled than they seem at first glance. 



</p>
</span>




  <Box className='reviewBox lowPicture swiper-no-swiping' sx={{ maxWidth: 400, flexGrow: 1 }}>
  


<AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {reviewImages.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (

<div>
<span className='reviewText'> {step.text} </span>

<br/><br/>

              <Box
                component="img"
                sx={{
                  height: 70,
                  display: 'inline-block',
                  maxWidth: 190,
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step.imgPath}
                alt={step.label}
              />
          </div>

            )  : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        variant='progress'
    
      />
    </Box>



<div className='bookNavigationButtons'>


<Link className='bookpageButton Left' to='/books/book-of-mirrors'>

<MdOutlineSwipeLeft />

  Book of Mirrors


</Link>

<Link className='bookpageButton Right' to='/books/rumours'>

Rumours

<MdOutlineSwipeRight />


</Link>


</div>






</div>




</SwiperSlide>




























      <SwiperSlide  className=''> </SwiperSlide>

</Swiper>
       </> 
            
  );
}