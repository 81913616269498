import * as React from "react"

import Seo from "../../components/seo"
import Slider from "../../components/corebook-badblood.js"

import {Helmet} from "react-helmet";




const mainPage = ({}) => {



  return (

<div>
    <Seo
        title='Bad Blood | Books | E.O. Chirovici'
        
        description='Bad Blood, a fiction novel written by Eugen Ovidiu Chirovici, also known as E.O. Chirovici, and who is the writer of the Book of Mirrors '

        keywords='Bad Blood, Eugen Ovidiu Chirovici, E.O. Chirovici, The Book of Mirrors, Sleeping Dogs, Sleeping Dogs The Film, Sleeping Dogs Movie'

      />

<Helmet>

</Helmet>



<Slider/>
     
    </div>
  )

}


export default mainPage;